/* ./src/index.css */

@import url("https://fonts.googleapis.com/css2?family=Barlow+Condensed&family=Bellefair&family=Lato:wght@400;700;900&family=Manrope:wght@200..800&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .no-border {
    border: none;
  }
}
